<template>
  <div id="app">
  <a-config-provider :locale="zh_CN">
   <router-view v-if="isShow"></router-view>
  </a-config-provider>
  </div>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
export default {
  provide(){   //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return{
      reload:this.reload
    }
  },
  data() {
    return {
      zh_CN,
      isShow:true   //控制视图是否显示的变量
    };
  },
  methods:{
    reload(){
      this.isShow=false;   //先关闭
      this.$nextTick(()=>{
        this.isShow=true  //再打开
      })
    }
  },
};
// require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
// Use only when you are using Webpack
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
