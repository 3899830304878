<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" collapsible :trigger="null">
      <div class="logo"/>
      <a-menu v-model:selectedKeys="selectedKeys" mode="inline" theme="dark">
        <a-menu-item key="defaultpath">
          <home-outlined />
         <router-link to="/"> 首页</router-link>
        </a-menu-item>
        <a-sub-menu key="2">
          <template #title>
            <span>
              <span>后台管理</span>
            </span>
          </template>
          <a-menu-item key="adminList">
            <router-link to="/adminList">管理员列表</router-link>
          </a-menu-item>
          <a-menu-item key="userlist">
            <router-link to="/userlist">用户列表</router-link>
          </a-menu-item>
          <a-menu-item key="project_tags">
            <router-link to="/project_tags">标签管理</router-link>
          </a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="3">
          <template #title>
            <span>
              <span>项目管理</span>
            </span>
          </template>
          <a-menu-item key="adminList">
            <router-link to="/projectlist">项目列表</router-link>
          </a-menu-item>
<!--          <a-menu-item key="project_netfile">-->
<!--            <router-link to="/project_netfile">网盘管理</router-link>-->
<!--          </a-menu-item>-->

<!--          <a-menu-item key="project_materials">-->
<!--            <router-link to="/project_materials">物料管理</router-link>-->
<!--          </a-menu-item>-->

<!--          <a-menu-item key="project_Subcontract">-->
<!--            <router-link to="/project_Subcontract">分包管理</router-link>-->
<!--          </a-menu-item>-->
<!--          <a-menu-item key="project_funds">-->
<!--            <router-link to="/project_funds">资金出入</router-link>-->
<!--          </a-menu-item>-->
<!--          <a-menu-item v-for="(item, index) in projectdata" :key="'project'+index" >-->
<!--            <router-link :to="'/projectlist?project_id='+item.id">{{ item.name }}</router-link>-->
<!--          </a-menu-item>-->
        </a-sub-menu>


        <a-menu-item key="9" @click="onLogout">
          <export-outlined />
          <span>退出</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <!--            内部嵌入页面-->
      <router-view/>
      <a-layout-footer style="text-align: center">
        这里是页脚
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>

import {Modal} from "ant-design-vue";
// import { useRouter } from "vue-router";
import {HomeOutlined,ExportOutlined} from '@ant-design/icons-vue';
import Storage from "../common/storage";

export default ({
  setup() {
  },
  components: {
    HomeOutlined,
    ExportOutlined
  },
  created() {
    const that = this;
    if (Storage.token === "") {
      that.$router.push({path: "/login"});
    }
    if (!sessionStorage.isloging) {
      Storage.token = "";
      that.$router.push({path: "/login"});
    } else {
      let times = new Date;
      let year = times.getFullYear();
      let month = times.getMonth() + 1;
      this.nowdata = (year.toString() + "-" + month.toString());
      console.log('now', that.nowdata);
      that.loading = true;
      // that.getprojectList()
    }
  },
  data() {
    return {
      collapsed: false,
      selectedKeys: ['defaultpath'],
      permissiondata: [],
      loading: false,
    };
  },
  methods: {
    flushCom(to){
      console.log('to',to);
      console.log('from',this.$route.query);

    },
    // async getprojectList() {
    //   console.log('getprojectList');
    //   this.loading = true;
    //   this.projectdata = [];
    //   let result = await this.Axios.post("/project/list", {
    //     uid: Storage.uid,
    //     token: Storage.token,
    //     customer_id: Storage.customer_id,
    //
    //   });
    //   if (result.success === true) {
    //
    //     this.projectdata = result.data
    //     // console.log("sessionStorage projectdata", sessionStorage.projectdata);
    //   }
    //   if (result.success === false) {
    //     Modal.error({
    //       title: result.message
    //     });
    //   }
    //   this.loading = false;
    // },
    onLogout() {
      const that = this;
      // const router = useRouter();
      Modal.confirm({
        title: "退出",
        content: "是否退出登录?",
        onOk() {
          Storage.token = "";
          that.$router.push({path: "/login"});
        }
      });
    },
  }
});
</script>
<style>
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>