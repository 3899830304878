/**
 * 仓库
 */
export default {
    set uid(value) {
        localStorage.setItem('uid', value)
    },

    get uid() {
        return localStorage.getItem('uid')
    },
    set token(value) {
        localStorage.setItem('token', value)
    },

    get token() {
        return localStorage.getItem('token')
    },
    set token_timeout(value) {
        localStorage.setItem('token_timeout', value)
    },

    get token_timeout() {
        return localStorage.getItem('token_timeout')
    },
    set login_name(value) {
        localStorage.setItem('login_name', value)
    },

    get login_name() {
        return localStorage.getItem('login_name')
    },
    set account(value) {
        localStorage.setItem('account', value)
    },

    get account() {
        return localStorage.getItem('account')
    },
    set customer_id(value) {
        localStorage.setItem('customer_id', value)
    },

    get customer_id() {
        return localStorage.getItem('customer_id')
    },
    set itemdata(value) {
        localStorage.setItem('itemdata', value)
    },

    get itemdata() {
        return localStorage.getItem('itemdata')
    },
}
