<template>
  <div>
    首页
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dashboard',
}
</script>

<style lang="scss" scoped>

</style>