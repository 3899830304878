import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '../../src/layout/index.vue' // 主框架路径
import refresh from '../views/refresh.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
  },
  {
    path: '/home',
    component: Layout,
    children: [
      {
        path: '/',
        component: () => import('../views/Dashboard.vue'),
        name: 'home',

      },
      {
        path: '/adminList',
        name: 'adminList',
        component: () => import('../views/admin_list.vue'),
      },{
        path: '/userlist',
        name: 'user_list',
        component: () => import('../views/user_list.vue'),
      },{
        path: '/projectList',
        name: 'project_List',
        component: () => import('../views/project_List.vue'),
      },{
        path: '/project_netfile',
        name: 'project_netfile',
        component: () => import('../views/project_netfile.vue'),
      },{
        path: '/project_materials',
        name: 'project_materials',
        component: () => import('../views/project_materials.vue'),
      },{
        path: '/project_item',
        name: 'project_item',
        component: () => import('../views/project_item.vue'),
      },{
        path: '/project_funds',
        name: 'project_funds',
        component: () => import('../views/project_funds.vue'),
      },{
        path: '/project_Subcontract',
        name: 'project_Subcontract',
        component: () => import('../views/project_Subcontract.vue'),
      },{
        path: '/project_filelist',
        name: 'project_filelist',
        component: () => import('../views/project_filelist.vue'),
      },{
        path: '/project_Info',
        name: 'project_Info',
        component: () => import('../views/project_Info.vue'),
      },{
        path: '/project_tags',
        name: 'project_tags',
        component: () => import('../views/project_tags.vue'),
      },{
        path: '/project_notification',
        name: 'project_notification',
        component: () => import('../views/project_notification.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: refresh
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
